export enum CompanyRole {
  ACADEMICS = 'Academics',
  SERVICE_PROVIDER = 'ServiceProvider',
  MITIGATION_ACTIVITY_PARTNER = 'MitigationActivityParticipant',
  TRADER = 'Trader',
  VALIDATION_VERIFICATION_ENTITY = 'ValidationVerificationEntities',
  DEV_BANK = 'DevBank',
  MANAGEMENT_COMPANY = 'ManagementCompany',
  GOV_REGULATOR = 'GovernmentRegulators',
  OBSERVERS = 'Observers',
  CIVIL_SOCIETY_ORG = 'CivilSocietyOrganization',
  CERTIFIER = 'Certifier',
  INTERNAL_ORGANIZATION = 'InternationalOrganization',
  PROGRAMME_DEVELOPER = 'ProgrammeDeveloper',
  MRV = 'MRV',
  GOVERNMENT = 'Government',
}
