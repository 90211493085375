export enum Sector {
  Energy = 'Energy',
  Transport = 'Transport',
  Buildings = 'Buildings',
  Extractives = 'Extractives',
  Industry = 'Industry',
  Hospitality = 'Tourism & Hospitality',
  Agriculture = 'Agriculture',
  Forestry = 'Forestry',
  Aquaculture = 'Fisheries & Aquaculture',
  Waste = 'Waste Management',
  Other = 'Other',
}
